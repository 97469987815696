import "./index.css";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

import * as React from "react";

// TODO: delete this page

const TERMS_OF_USE_URL = "https://faintlines.com/termsofuse/";

export default function TermsPage() {
    return (
        <Layout>
            <title>{"Terms of Use | Peek a Phone"}</title>
            <Helmet>
                <meta
                    http-equiv="refresh"
                    content={`0; url=${TERMS_OF_USE_URL}`}
                />
            </Helmet>
            <h1>{"Terms of Use"}</h1>
            <p>
                <a href={TERMS_OF_USE_URL}>{"Click here"}</a>
                {" if you are not being redirected."}
            </p>
        </Layout>
    );
}
